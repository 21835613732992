<template>
  <div>
    <div v-show="!isBj">
      <!-- 下载模块 -->
      <!-- 下载栏 -->
      <div class="xzl">
        <div class="xzl-div-btn">
          <el-button class="xzl-div-btns" @click="tjyh">
            <i style="font-size: 14px" class="el-icon-plus"></i>添加用户
          </el-button>
        </div>
        <!-- 添加选择用户框 -->
        <el-dialog title="选择添加用户类型" :visible.sync="dialogFormVisible">
  <el-form :model="formQryhlx">
    <!-- :label-width="formLabelWidth" -->
    <el-form-item label="用户类型" >
      <el-select v-model="formQryhlx.userType" placeholder="请选择用户类型">
        <el-option label="管理员" value="1"></el-option>
        <el-option label="客户" value="2"></el-option>
      </el-select>
    </el-form-item>
  </el-form>
  <div slot="footer" class="dialog-footer">
    <el-button @click="dialogFormVisible = false">取 消</el-button>
    <el-button type="primary" @click="qdtj">确 定</el-button>
  </div>
</el-dialog>
      </div>
      <!-- 查询栏 -->
      <!-- table模块  ref表格数据-->
      <div style="margin-top: 20px">
        <el-table
          ref="multipleTable"
          :data="tableData"
          border
          style="width: 100%"
          :header-cell-style="{ background: 'rgb(245,250,254)', color: '#000' }"
        >
          <el-table-column
            align="center"
            header-align="center"
            label="ID"
            prop="id"
          >
          </el-table-column>
          <el-table-column
            align="center"
            header-align="center"
            label="用户角色"
            prop="username"
          >
          
          </el-table-column>
          <el-table-column
            align="center"
            header-align="center"
            label="角色名称"
            prop="roleName"
          ></el-table-column>
          <el-table-column
            align="center"
            header-align="center"
            label="公司名称"
            prop="company"
          >
          </el-table-column>
          <el-table-column
            align="center"
            header-align="center"
            label="手机号"
            prop="phone"
          >
          </el-table-column>
          <el-table-column
            align="center"
            header-align="center"
            label="状态"
            prop="isDestroy"
          >
            <template slot-scope="scope">
              <el-tooltip
                :content="scope.row.isDestroy == 1 ? '关闭' : '开启'"
                placement="top"
              >
                <el-switch
                  :value="scope.row.isDestroy === 0"
                  @change="handleSwitchChange(scope.$index, $event,scope.row)"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                >
                </el-switch>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            header-align="center"
            align="center"
            label="操作"
            width="150"
            style="display: flex; justify-content: left"
          >
            <template slot-scope="scope">
              <i
                class="el-icon-edit"
                title="编辑"
                @click="handleEdit(scope.$index, scope.row)"
              ></i>
              <i
                class="el-icon-delete"
                @click="handleDelect(scope.$index, scope.row)"
                title="删除"
              ></i>
            </template>
          </el-table-column>
        </el-table>

        <!-- 分页器 -->
        <div v-if="tableData.length" style="text-align: left; margin-top: 5px">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="pageSizes"
            :page-size="PageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="tableData.length"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 理赔 -->
    <!-- 编辑信息 -->
    <div v-show="isBj" style="width: 100%; border: 1px solid #eee">
      <div
        style="
          font-size: 14px;
          font-weight: 900;
          background-color: rgb(240, 250, 254);
          line-height: 36px;
          height: 36px;
          padding-left: 12px;
          border-bottom: 1px solid #eee;
        "
      >
       {{ isAdd ? pushYh : '编辑用户'}} 
      </div>
      <div style="margin: 20px">
        <el-form ref="form" :model="form" label-width="120px">
          <!-- 编辑显示 -->
          <el-form-item v-show="!isAdd" label="用户名称">
            {{ form.username }}
          </el-form-item>
          <!-- 新增显示 -->
          <el-form-item v-show="isAdd" label="用户名称">
            <el-input v-model="form.username" style="width: 30vw"></el-input>
          </el-form-item>
          <el-form-item :label="isAdd == true ? '新密码' : '旧密码'">
            <el-input v-model="form.oldPassword" style="width: 30vw"></el-input
            >6 位以上，可以包含数字、大小写英文字母、下划线、@
          </el-form-item>
          <el-form-item :label="isAdd == true ? '确认密码' : '新密码'">
            <el-input v-model="form.newPassword" style="width: 30vw"></el-input>
          </el-form-item>
          <el-form-item label="公司名称">
            <el-input v-model="form.company" style="width: 30vw"></el-input>
          </el-form-item>
          <el-form-item label="手机号">
            <el-input v-model="form.phone" style="width: 30vw"></el-input>
            必须为正确手机号格式，已经分配的手机号不可再次分配
          </el-form-item>
          <el-form-item label="启用状态">
            <el-radio-group v-model="form.isDestroy">
              <el-radio :label="0">启用</el-radio>
              <el-radio :label="1">禁用</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="选择角色">
            <!-- <el-radio-group v-model="form.userType">
              <el-radio :label="'1'">管理员</el-radio>
              <el-radio :label="'2'">客户</el-radio>
            </el-radio-group> -->
            <el-select v-model="form.roleName" placeholder="请选择角色">
        <el-option v-for="item in userTypeList" :key="item.id" :label="item.roleName" :value='item.roleName'></el-option>
      </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">保存</el-button>
            <el-button @click="qxbj">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
// 引入table top 组件
import yhglTableTop from "@/components/Public/Tabletop/yhglForm.vue";
// 引入table组件
import xtTable from "@/components/Public/Table/index.vue";
import {
  UserData,
  OpenUserData,
  UpdataUserData,
  // OpenUserRoleData,
  SaveUserData,
  UserDataById,
  DeleteUserId,
  GetUserType
} from "@/axios/Xtsz/Ywgl/yhgl.js"; //接口
import * as XLSX from "xlsx"; //导出类型
export default {
  components: {
    xtTable,
    yhglTableTop,
  },
  data() {
    return {
      templateId: "1", // 这个就是页面的ref 用来判定需要哪一个模版
      tableData: [], //表格数据
      form: {
        username:'',
        oldPassword: "",
        newPassword: "",
        company: "",
        phone: "",
        isDestroy: 0,
        userType:'',
        roleName:''
      },
      status: [], //请选择所属角色
      formInline: {
        roleName: "",
        current:'',
        size:'',
      }, //查询项
      isBj: false, //true是编辑 false不是编辑
      currentPage: 1,
      PageSize: 10,
      pageSizes: [10, 20, 30, 40],
      isAdd: false, //true是添加 false不是添加
      dialogFormVisible: false,//添加弹框显示
      formQryhlx: {
        userType: '',
        },
        // 提示用户当前添加用户是谁
      pushYh:'',
      userTypeList: [],
    };
  },
  methods: {
    // 导出xt-table中的table数据
    handleExport() {
      // 从TableComponent获取数据
      const tableData = this.$refs.tableComponent.getTableData();
      // const tableTop = this.$refs.tableComponent.getTableTop()[0];
      const tableValue = this.$refs.tableComponent.getTableTop()[1];
      // 将数据转换为工作表
      const ws = XLSX.utils.aoa_to_sheet([
        // tableTop,
        ...tableData.map((row) => {
          const values = tableValue.map((header) => {
            // 将 header 转换为小写（如果原对象的键名也是小写的话，这一步可以省略）
            // 但由于你的键名是大写和混合的，所以这里不转换
            // 直接使用原 header 作为键名来访问对象属性
            return row[header] || ""; // 如果属性不存在，则返回空字符串
          });
          return values;
        }),
      ]);
      // 创建一个工作簿并添加工作表
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      // 导出xlsx文件
      XLSX.writeFile(wb, `表格数据_${this.templateId}.xlsx`);
    },

    // 添加用户操作
    tjyh() {
      // 清空form内容
      this.form = {};
      // 打开编辑弹框
      this.dialogFormVisible=true;

      this.isAdd = true; //添加用户标识
    },
    // 确定添加用户
    qdtj(){
      if (this.formQryhlx.userType=='') {
        this.$message.error('请选择用户类型再进行添加');
      }else{
        this.dialogFormVisible=false;
        if(this.formQryhlx.userType=='1'){
          this.pushYh='添加-管理员'
        }else{this.pushYh='添加-客户'}
        GetUserType(this.formQryhlx).then((res) => {
          if (res.code == 200) {
            this.form.userType=this.formQryhlx.userType;
            this.$message.success("选择成功");
            this.userTypeList=res.data;
            console.log(this.userTypeList,'普通人');
            
          }else{
            this.$message.error(res.msg);
          }
        });
      this.isBj = true;
      }
    },
    // 取消编辑用户
    qxbj() {
      this.form.phone=''
      this.isBj = false;
      // this.form = {}; // 清空表单数据
      
    },
    // 查询
    onQuery() {
      UserDataById({ rolename: this.formInline.rolename }).then((res) => {
        if (res.code == 200) {
          this.tableData = res.data;
        }
      });
    },
    handleSizeChange(val) {
      this.PageSize = val;
      this.$emit("PageSize", val);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.$emit("currentPage", val);
    },
    //初始化获取角色
    queryUserData() {
      UserData({current:1,size:50}).then((res) => {
        if (res.code == 200) {
          this.tableData = res.data;
          console.log(res.data);
        }
      });
    },
    // 编辑用户信息
    handleEdit(row, rowData) {
      this.isAdd=false;
      this.isBj = true; //切换编辑添加页面
      // let rowNowData=JSON.parse(JSON.stringify(rowData)); //深拷貝数据防止，table和form一同修改
      this.form.id=rowData.id;
      this.form.username=rowData.username;
      this.form.company=rowData.company;
      this.form.phone=rowData.phone;
      this.form.userType=rowData.userType;
      this.form.isDestroy=rowData.isDestroy;
      console.log(this.form);
      this.formQryhlx.userType=rowData.userType;
      // 查询可选择的角色
      GetUserType(this.formQryhlx).then((res) => {
          if (res.code == 200) {
            this.form.userType=this.formQryhlx.userType;
            this.userTypeList=res.data;
          }else{
            this.$message.error(res.msg);
          }
        });
      this.form.roleName=rowData.roleName;
    },
    // 保存编辑用户信息
    onSubmit() {
      console.log(this.form,'this.form');
      const mobilePattern = /^1(3|4|5|6|7|8|9)\d{9}$/;  
      const PhoneTest =mobilePattern.test(this.form.phone);  
      
      // if (this.form.oldPassword == "") {
      //   this.$message.error("请输入旧密码");
      //   return;
      // }
      // if (this.form.oldPassword!='' && this.form.newPassword == "") {
      //   this.$message.error("请填写再次确认密码");
      //   return;
      // }
      // if (this.form.roleName == "") {
      //   this.$message.error("请选择所属角色");
      //   return;
      // }
      if (PhoneTest == false) {
        this.$message.error("请输入正确格式手机号");
        return;
      }

      // 新增
      if (this.isAdd) {
        this.form.password = this.form.newPassword;
        SaveUserData(this.form).then((res) => {
          if (res.code == 200) {
            this.$message.success("编辑保存成功");
            this.isBj = false; //退出编辑页
            this.queryUserData(); // 刷新表格初始化
          } else {
            this.$message.error(res.message);
          }
        });
      }
      // 编辑
      else {
        console.log("编辑");
        console.log(this.form);
        
        UpdataUserData(this.form)
          .then((res) => {
            if (res.code == 200) {
              this.$message.success("编辑保存成功");
              this.isBj = false; //退出编辑页
              this.form.newPassword='';
              this.form.oldPassword='';
              this.queryUserData(); // 刷新表格初始化
            } else {
              this.$message.error(res.message);
            }
          })
          .catch((err) => {
            console.log(err.message);
            this.$message.error(err.message);
          });
      }
    },
    // 删除
    handleDelect(index, rowData) {
      this.$confirm("是否确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          DeleteUserId({ userId: rowData.id }).then((res) => {
            if (res.code == 200) {
              this.$message.success("删除成功");
              this.queryUserData(); // 刷新表格初始化
            } else {
              this.$message.error(res.message);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 切换
    handleSwitchChange(index, value,row) {
      console.log(row);
      this.$message.warning('请前往编辑修改状态！！！')
      return;
      // 假设 this.tableData 是你的表格数据源
      // this.$set(this.tableData, index, {
      //   ...this.tableData[index],
      //   isDestroy: value ? 1 : 0, // 将布尔值转换回 0 或 1
      // });
    },
  },
  mounted() {
    this.queryUserData();
  },
};
</script>
<style lang="less" scoped>
// 下载栏
.xzl {
  border: 1px, solid, rgb(221, 221, 221);
  display: flex;

  // 上传+导入
  .xzl-div-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;

    // 按钮公共样式
    .xzl-div-btns {
      height: 28px;
      line-height: 28px;
      padding: 0 10px;
      font-size: 12px;
      color: white;
      font-weight: 900;
      background-color: rgba(231, 102, 109);
      margin-left: 1vw;
    }

    .xzl-div-btns:hover {
      opacity: 0.8;
      /* 举例使用 Element UI 的主题色作为文字颜色 */
    }
  }
}

// 查询栏
.cxl {
  border: 1px solid rgb(221, 221, 221);
  position: relative;
  padding: 10px;
  padding-bottom: 0px;

  // input输入框 label
  /deep/ .custom-form-item .el-form-item__label {
    font-size: 12px !important;
    /* 修改字体大小 */
    width: 72px;
    /* 限制宽度 */
    overflow: hidden;
    /* 如果需要，可以添加溢出隐藏 */
    text-overflow: ellipsis;
    /* 文本溢出时显示省略号 */
    white-space: nowrap;
    /* 保持文本在一行内 */
  }

  // input输入框
  .cxl-input {
    width: 180px;
    font-size: 12px;
    height: 30px !important;
    /* 使用 !important 确保覆盖默认样式 */
  }

  // 修改input高度
  /deep/ .el-input__inner {
    height: 26px !important;
  }

  // 修改el-form-item行之间间距
  .el-form-item {
    margin-bottom: 10px !important;
  }

  // 查询项 按钮栏
  .cxl-form-btns {
    position: absolute;
    bottom: 0px;
    right: 30px;

    // 按钮
    .cxl-form-btn {
      height: 28px;
      line-height: 28px;
      padding: 0 10px;
      font-size: 12px;
      color: white;
      font-weight: 900;
      background-color: rgba(0, 150, 136);
    }

    .cxl-form-btn:hover {
      opacity: 0.8;
      /* 举例使用 Element UI 的主题色作为文字颜色 */
    }
  }
}
/deep/.el-form-item {
  //   margin-bottom: 0px; //清空表格行间距margin
  // line-height:58px !important;
}

/deep/.el-form-item__label {
  display: block;
  height: 100%;
  //   border-top: 1px solid #eee !important;
  /* 举例，根据需要设置 */
  display: flex;
  align-items: center;

  /* 垂直居中内容 */
}

/deep/.el-form-item__content {
  //   border: 1px solid #eee;
}
</style>
