import { render, staticRenderFns } from "./sxfsz.vue?vue&type=template&id=248a3d7f&scoped=true"
import script from "./sxfsz.vue?vue&type=script&lang=js"
export * from "./sxfsz.vue?vue&type=script&lang=js"
import style0 from "./sxfsz.vue?vue&type=style&index=0&id=248a3d7f&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "248a3d7f",
  null
  
)

export default component.exports