// 调用axios封装
import request from '@/axios/api'


// 获取所有客户数据
export const getOaData = data => {
    return request({
        url: '/tms/all/customer',
        data,
        method: 'post'
    })
}
// 分配客户
export const getOaSpData = data => {
    return request({
        url: '/tms/assign/customers',
        data,
        method: 'post',
    
    })
}
// 查询所有管理员信息
export const tableFrom = data => {
    return request({
        url: '/tms/all/manager',
        data,
        method: 'post',
      
    })
}



