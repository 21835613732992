// 调用axios封装
import request from '@/axios/api'


// 预存管理table初始化
export const getYcglData = data => {
    return request({
        url: '/pre/save/page/list',
        data,
        method: 'post'
    })
}
// 预存管理详情
export const getYcglXq = data => {
    return request({
        url: '/pre/save/detail',
        data,
        method: 'post'
    })
}
// 提交到OA
export const getPushOa = data => {
    return request({
        url: '/pre/commit/formdata/oa',
        data,
        method: 'post'
    })
}


