// 调用axios封装
import request from '@/axios/api'


// 退出登录
export const editUser = data => {
    return request({
        url: '/tms/find/role/byId',
        data,
        method: 'post'
    })
}
// 修改密码
export const SaveUserData = data => {
    return request({
        url: '/tms/role/setting',
        data,
        method: 'post'
    })
}
// 添加角色 角色信息
export const addUserData = data => {
    return request({
        url: '/tms/save/role',
        data,
        method: 'post'
    })
}
//获取系统功能点页面配置
export const userTableTop = data => {
    return request({
        url: '/tms/function/config',
        data,
        method: 'post'
    })
}
// 获取table所有数据 
export const userTableData = data => {
    return request({
        url: '/decl/show/ins/formdata',
        data,
        method: 'post'
    })
}
// 删除table表格数据
export const deleteTableData = data => {
    return request({
        url: '/decl/delete/ins/formdata',
        data,
        method: 'post'
    })
}
// 编辑table表格数据
export const changeTableData = data => {
    return request({
        url: '/decl/change/ins/formdata',
        data,
        method: 'post'
    })
}