<template>
  <div>
    <div v-show="!isModify">
      <!-- 下载模块 -->
      <div
        style="
          border: 1px, solid, rgb(221, 221, 221);
          height: auto;
          background-color: rgb(245, 250, 254);
          margin-bottom: 10px;
        "
      >
        <div>


          <!-- 查询栏 -->
          <div class="cxl">
            <el-form
              :inline="true"
              :model="formLabelAlign"
              class="demo-form-inline">
              <el-form-item  label="管理员" class="custom-form-item">
                <el-select v-model="khXx.userId">
                  <el-option
                    v-for="item in options"
                    :key="item.id"
                    :label="item.username"
                    :value="item.id"

                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item class="cxl-form-btns">
                <!-- <el-button class="cxl-form-btn" 
                  >查询</el-button
                > -->
                <!-- @click="exportToExcel" -->
                <!-- <el-button
                  class="cxl-form-btn"
                  style="background-color: rgba(231, 102, 109)"
                  
                  >导出</el-button
                > -->
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
      <!-- table模块  ref表格数据 //querytable重新请求-->
      <div>
        <el-table
    ref="multipleTable"
    :data="tableData"
    border
    style="width: 100%" @selection-change="handleSelectionChange">
    <el-table-column type="selection" width="55" > </el-table-column>
    <el-table-column
      prop="id"
      label="ID"
      width="220">
    </el-table-column>
    <el-table-column
      prop="customerName"
      label="客户名称"
      width="180">
    </el-table-column>
    <el-table-column
      prop="company"
      label="公司">
    </el-table-column>
    <el-table-column
      prop="phone"
      label="手机号">
    </el-table-column>
  </el-table>
  <div style="margin-top: 5px">
    <el-button
      class="table-bottom-btns"
      style="background-color: rgb(231, 102, 109)"
      type="danger"
      @click="handleDelete()"
    >
      <i style="font-size: 15px" class="el-icon-delete"></i
      >确认提交</el-button>
    <el-button class="table-bottom-btns" @click="toggleSelection()">
      <i style="font-size: 15px" class="el-icon-s-release"></i
      >取消选择</el-button
    >
  </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getOaData, getOaSpData, tableFrom } from '@/axios/khgl'
// 引入table top 组件
import xtTableTop from '@/components/Public/Tabletop/index.vue'
// 引入table组件
import xtTable from '@/components/Public/Table/index.vue'
import * as XLSX from 'xlsx';  //导出类型
export default {
    components: {
        xtTable,
        xtTableTop
    },
    data() {
        return {
            templateId: '1', // 这个就是页面的ref 用来判定需要哪一个模版  
            formLabelAlign: {
        current: 1,
        size: 50,
        pageType:'0',
        insType: "07",
        startDate: "",
        endDate: "",
        applicantName: "", //投保人姓名
        insuredName: "", //被保险人姓名
        vehiclePlateNumber: "", //车牌号码
        status:[2,5] //确定是申报入口
      },
            tableData: [],
        // 编辑
        isModify:false,
        options:[], //管理员类型
        khXx:{
          username:'',
          userId:'',
          customersVOList:[],
        },
        };

    },
    methods: {
        // 导出xt-table中的table数据
        handleExport() {
            // 从TableComponent获取数据  
            const tableData = this.$refs.tableComponent.getTableData();

            // 将数据转换为工作表  
            const ws = XLSX.utils.json_to_sheet(tableData);

            // 创建一个工作簿并添加工作表  
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

            // 导出xlsx文件  
            XLSX.writeFile(wb, `表格数据_${this.templateId}.xlsx`);
        },
            // 编辑
    handleModify(index, row) {
      this.isModify = true;

    },
    // 选中table 复选框数据
  handleSelectionChange(val) {
    this.multipleSelection = val;
    console.log(val);
    let newRecords = this.multipleSelection.map(record => ({  
  id: record.id,  
  customerName: record.customerName  
})); 
this.khXx.customersVOList = newRecords;
    console.log(newRecords);
    
  }, 
  // 确认提交
  handleDelete() {
    if (this.khXx.userId=='') {
      this.$message({
        message: "请选择管理员",
        type: "error",
      });
      return;
    }
    if (this.khXx.customersVOList.length==0) {
      this.$message({
        message: "请选择客户信息",
        type: "error",
      });
      return;
    }
    let targetUser = this.options.find(option => option.id === this.khXx.userId);  
    this.khXx.username=targetUser.username;
    this.$confirm("确认提交当前客户信息?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    }).then(() => {
      getOaSpData(this.khXx).then((res) => {
       if (res.code==200) {
        this.$message({  
          message: "提交成功",
          type: "success",
        });
        // 重新获取客户名称信息
        this.getKhxx();
       }
        else{
          this.$message({
            message: res.msg,
            type: "error",
          });
        }
      })
    })
  },
  // 表格取消 复选
  toggleSelection(rows) {
    if (rows) {
      rows.forEach((row) => {
        this.$refs.multipleTable.toggleRowSelection(row);
      });
    } else {
      this.$refs.multipleTable.clearSelection();
    }
  },
    //获取管理员数据
    getGlyxx(){
      let a={};
      tableFrom(a)
      .then(res=>{
        console.log(res)
        if (res.code == 200) {
          console.log(res.data);
          this.options=res.data;
        }
      })
    },
    //获取所有客户数据
    getKhxx(){
      getOaData({current: 1,size: "50"})
      .then(res=>{
        if (res.code == 200) {
          console.log(res.data);
          this.tableData = res.data.records;
        }
      })
}
    },
    mounted(){
this.getGlyxx();
this.getKhxx();
    }
}
</script>


<style lang="less" scoped>
.table-bottom-btns {
height: 28px;
line-height: 28px;
padding: 0 10px;
font-size: 12px;
color: white;
font-weight: 900;
background-color: rgba(0, 150, 136);
}

.table-bottom-btns:hover {
opacity: 0.8;
/* 举例使用 Element UI 的主题色作为文字颜色 */
}
    // 查询栏
.cxl {
  border: 1px solid rgb(221, 221, 221);
  position: relative;
  padding: 10px;
  padding-bottom: 0px;

  // input输入框 label
  /deep/ .custom-form-item .el-form-item__label {
    font-size: 12px !important;
    /* 修改字体大小 */
    width: 72px;
    /* 限制宽度 */
    overflow: hidden;
    /* 如果需要，可以添加溢出隐藏 */
    text-overflow: ellipsis;
    /* 文本溢出时显示省略号 */
    white-space: nowrap;
    /* 保持文本在一行内 */
  }

  // input输入框
  .cxl-input {
    width: 180px;
    font-size: 12px;
    height: 30px !important;
    /* 使用 !important 确保覆盖默认样式 */
  }

  // 修改input高度
  /deep/ .el-input__inner {
    height: 26px !important;
  }

  // 修改el-form-item行之间间距
  .el-form-item {
    margin-bottom: 10px !important;
  }

  // 查询项 按钮栏
  .cxl-form-btns {
    position: absolute;
    bottom: 0px;
    right: 30px;

    // 按钮
    .cxl-form-btn {
      height: 28px;
      line-height: 28px;
      padding: 0 10px;
      font-size: 12px;
      color: white;
      font-weight: 900;
      background-color: rgba(0, 150, 136);
    }

    .cxl-form-btn:hover {
      opacity: 0.8;
      /* 举例使用 Element UI 的主题色作为文字颜色 */
    }
  }
}
</style>