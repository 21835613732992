<template>
    <div>
        <!-- 用户信息 -->
        <div v-show="!isBj">
            <!-- 用户信息操作栏 -->
            <div class="xzl">
                <div class="xzl-div-btn">
                    <!-- @click="tjfj" -->
                    <el-button class="xzl-div-btns" @click="handleAdd">
                        <i style="font-size: 14px" class="el-icon-plus"></i>添加角色
                    </el-button>
                    <!-- 上传excel -->
                </div>
            </div>
            <!-- 用户信息table -->
            <div style="margin-top: 20px">
                <el-table
                        ref="multipleTable"
                        :data="tableData"
                        border
                        style="width: 100%"
                        :header-cell-style="{ background: 'rgb(245,250,254)', color: '#000' }"
                >
                    <el-table-column type="selection" width="55"></el-table-column>
                    <el-table-column
                            align="center"
                            header-align="center"
                            label="ID"
                            prop="id"
                    >
                    </el-table-column>
                    <el-table-column
                            align="center"
                            header-align="center"
                            label="角色名称"
                            prop="roleName"
                    >
                    </el-table-column>
                    <el-table-column
                            header-align="center"
                            align="center"
                            label="操作"
                            width="150"
                            style="display: flex; justify-content: left"
                    >
                        <template slot-scope="scope">
                            <i
                                    class="el-icon-edit"
                                    title="编辑"
                                    @click="handleEdit(scope.$index, scope.row)"
                            ></i>
                            <i
                                    class="el-icon-delete"
                                    title="删除"
                                    @click="handleDelete(scope.$index, scope.row)"
                            ></i>
                        </template>
                    </el-table-column>
                </el-table>

                <!-- 分页器 -->
                <div v-if="tableData.length" style="text-align: left; margin-top: 5px">
                    <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="currentPage"
                            :page-sizes="pageSizes"
                            :page-size="PageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="tableData.length"
                    >
                    </el-pagination>
                </div>
            </div>
        </div>
        <!-- 编辑信息 -->
        <div v-show="isBj" style="width: 100%; border: 1px solid #eee">
            <div
                    style="
          font-size: 14px;
          font-weight: 900;
          background-color: rgb(240, 250, 254);
          line-height: 36px;
          height: 36px;
          padding-left: 12px;
          border-bottom: 1px solid #eee;
        "
            >
                {{ isAdd == true ? "添加角色" : "编辑角色" }}
            </div>
            <el-form
                    label-position="left"
                    label-width="270px"
                    :model="formLabelAlign"
            >
                <el-form-item label="角色名称" prop="name">
                    <el-input
                            v-model="formLabelAlign.roleName"
                            style="margin: 28px 10px 0px 10px; width: 45vw"
                    ></el-input>
                </el-form-item>
                <el-form-item label="权限设置">
                    <div>申报平台</div>
                    <!-- 预存管理-->
                    <div style="margin: 28px 10px 0px 10px; width: 55vw">
                        <el-tree
                                :data="qxData"
                                :props="defaultProps"
                                show-checkbox
                                node-key="id"
                                @node-click="handleNodeClick"
                                @check-change="handleCheck"
                                default-expand-all
                                ref="tree"
                                :check-strictly="false"
                        >
                        </el-tree>
                    </div>
                </el-form-item>
            </el-form>
        </div>
        <!-- <el-form-item> -->
        <div v-show="isBj" style="margin-top: 20px">
            <el-button type="primary" @click="onSubmit">确定提交</el-button>
            <el-button @click="qxbj">取消</el-button>
        </div>
        <!-- </el-form-item> -->
    </div>
</template>
<script>
    // 复选框复用
    import CheckboxGroup from "@/components/Views/Yhgl/checkBox.vue";
    import {
        UserData,
        AllQxData,
        AllkhData,
        editUser,
        SaveUserData,
        addUserData,
        DeleteUser
    } from "@/axios/Xtsz/Ywgl/jsgl.js";

    export default {
        components: {
            CheckboxGroup,
        },
        data() {
            return {
                isBj: false, //true是编辑 false不是编辑
                tableData: [],
                formLabelAlign: {
                    roleName: "",
                    systemNavConfigVOList:[],
                },
                cities1: [],
                currentPage: 1,
                PageSize: 10,
                pageSizes: [10, 20, 30, 40],
                isAdd: false, //添加标识 true为添加 false为编辑
                userName: "", //传给子组件角色名称在编辑中显示
                localCheckAll: false,
                isIndeterminate: false,
                localCheckedCities: [],
                khData: [],//所有客户信息
                value1: [],
                qxData: [],//所有权限
                defaultProps: {
                    children: 'menuConfigVOList',
                    label: 'name'
                },
                checkedNodes: [], // 用于存储选中的节点
                cunchuOA:[]
            };
        },
        methods: {
            handleSizeChange(val) {
                this.PageSize = val;
                this.$emit("PageSize", val);
            },
            handleCurrentChange(val) {
                this.currentPage = val;
                this.$emit("currentPage", val);
            },
            getMenuIds(navConfig) {  
    // 遍历 systemNavConfigVOList  
    return navConfig.systemNavConfigVOList.reduce((ids, navItem) => {  
        // 对每个 navItem 的 menuConfigVOList 进行遍历  
        console.log(ids,navItem);
        if (navItem.menuConfigVOList.length==0) {
            this.cunchuOA.push(navItem.id);
            this.cunchuOA = this.cunchuOA.filter((item, index, self) => {  
                return self.indexOf(item) === index;  
});
console.log(this.cunchuOA,'存储');

        }
        return ids.concat(navItem.menuConfigVOList.map(menu => menu.id));  
    }, []); // 初始值为空数组  
} ,
            // 编辑
            handleEdit(index, row) {
                this.cunchuOA=[]
                this.isBj = true; //打开编辑
                this.isAdd = false; //添加标识 true为添加 false为编辑
                const roleid = {roleId: row.id};
                editUser(roleid).then((res) => {
                    this.formLabelAlign.roleName = row.roleName;
                    this.formLabelAlign.roleId = row.id;
                    this.cities1 = res.data.navigationVO;
                    console.log(res,'oa');
                    
                    // 在组件挂载后，根据isAssigned的值来设置localCheckedCities
                    // 根据cities中的isAssigned初始化localCheckedCities
                    const A = this.getMenuIds(this.cities1);  
                    const menuIds =[...A,...this.cunchuOA]
                    console.log(menuIds, "hahah");
                    this.$refs.tree.setCheckedKeys(menuIds,true)//默认选中状态
                });
            },
            // 删除
            handleDelete(index,row){
                const roleid = {id: row.id};
                DeleteUser(roleid).then(res => {
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                    this.queryUserData(); // 刷新表格初始化
                });
            },
            // 添加
            handleAdd() {
                this.$refs.tree.setCheckedKeys([],true)//默认非选中状态
                this.formLabelAlign = {}; // 清空数据
                this.formLabelAlign.systemNavConfigVOList=[]
                this.isBj = true; //打开编辑
                this.isAdd = true; //添加标识 true为添加 false为编辑
                console.log(this.formLabelAlign, "formLabelAlign");


            },
            handleNodeClick(data) {
                console.log(data);
            },
            // 把所有选中节点状态变为true
            updateIsAssigned(nodes) {  
  nodes.forEach(node => {  
    // 更新当前节点的 isAssigned  
    node.isAssigned = "true";  
  
    // 如果节点有子节点，则递归调用 updateIsAssigned  
    if (node.children && node.children.length > 0) {  
      this.updateIsAssigned(node.children);  
    }  
  
    // 如果节点有 functionPointList，也更新这些的 isAssigned  
    if (node.functionPointList && node.functionPointList.length > 0) {  
      node.functionPointList.forEach(functionPoint => {  
        functionPoint.isAssigned = "true";  
      });  
    }  
  });  
},
// 选中权限设置事项
            handleCheck() {
              console.log();
              
                let nodes = this.$refs.tree.getCheckedNodes().concat(this.$refs.tree.getHalfCheckedNodes());
                console.log(this.$refs.tree.getHalfCheckedNodes());
                
                let subNode = {};
                let parentNodes = [];
                nodes.forEach(node => {
                    if (node.navId === null || node.navId === undefined) {
                        parentNodes.push(node);
                    } else {
                        if (!subNode[node.navId]) {
                            subNode[node.navId] = [];
                        }
                        subNode[node.navId].push(node);
                    }
                });
                const newParentNodes = parentNodes.map(parentNode => {
                    const newNode = { ...parentNode };
                    newNode.menuConfigVOList= subNode[parentNode.id] || [];
                    return newNode;
                });
                this.formLabelAlign.systemNavConfigVOList= newParentNodes;
                this.updateIsAssigned(this.formLabelAlign.systemNavConfigVOList);  

            },
            selection(arrDa){
            this.$nextTick(() => {
                this.$refs.tree.setCheckedKeys([],true)//默认选中状态
                console.log('zhixing');
                
            })
        },
            handleCheckChange(qxData) {
                this.checkedNodes.push(qxData);
                console.log(this.checkedNodes, '睡大觉很好的');

            },
            //初始化获取角色
            queryUserData() {
                UserData().then((res) => {
                    if (res.code == 200) {
                        console.log(res.data);

                        this.tableData = res.data;
                    }
                });
            },
            // 获取所有客户信息
            getKhData() {
                AllkhData().then((res) => {
                    if (res.code == 200) {
                        this.khData = res.data;
                        console.log(res.data, '参数');
                    }
                });
                AllQxData().then((res) => {
                    if (res.code == 200) {
                        this.qxData = res.data.systemNavConfigVOList;
                    }
                });
            },
            // 处理结构满足后端接口结构
            transformFormLabelAlign(original) {  
    const transformed = {  
        ...original, // 复制原始对象中的所有属性  
        permissions: { // 添加新的permissions对象  
            systemNavConfigVOList: original.systemNavConfigVOList // 将systemNavConfigVOList移动到permissions对象下  
        }  
    };  
    // 如果不需要原始的systemNavConfigVOList属性，可以将其删除  
    delete transformed.systemNavConfigVOList;  
  
    return transformed;  
},
            // 保存编辑用户信息
            onSubmit() {
                // 新增
                if (this.isAdd) {
                    // 验证是否有空值
                    if (this.formLabelAlign.roleName == "") {
                        this.$message.error("角色名称不能为空，请填写角色名称再保存");
                        return;
                    }
                const transformedFormLabelAlign = this.transformFormLabelAlign(this.formLabelAlign);
                console.log('参数结构',transformedFormLabelAlign);
                    addUserData(transformedFormLabelAlign).then((res) => {
                        if (res.code == 200) {
                            this.$message.success("编辑保存成功");
                            this.isBj = false; //退出编辑页
                            this.queryUserData(); // 刷新表格初始化
                        } else {
                            this.$message.error(res.message);
                        }
                    });
                }
                // 编辑
                else {
                    // 验证是否有空值
                    if (this.formLabelAlign.roleName == "") {
                        this.$message.error("角色名称不能为空，请填写角色名称再保存");
                        return;
                    }
                    const transformedFormLabelAlign = this.transformFormLabelAlign(this.formLabelAlign);
                    SaveUserData(transformedFormLabelAlign)
                        .then((res) => {
                            if (res.code == 200) {
                                this.$message.success("编辑保存成功");
                                this.isBj = false; //退出编辑页
                                this.queryUserData(); // 刷新表格初始化
                            } else {
                                this.$message.error(res.message);
                            }
                        })
                        .catch((err) => {
                            console.log(err.message);
                            this.$message.error(err.message);
                        });
                }
            },
            // 取消编辑用户
            qxbj() {
                this.isBj = false;
                this.form = {}; // 清空表单数据
            },
            // 全选
            handleCheckAllChange(val) {
                this.localCheckedCities = val ? this.cities1.map((city) => city.id) : [];
                 //保存指定参数
                this.cities1.map((city) => {
                    if (this.localCheckedCities.includes(city.id)) {
                        city.isAssigned = true;
                    } else {
                        city.isAssigned = false;
                    }
                });
                this.isIndeterminate = false; // 改变全选状态
                this.localCheckAll = val;
                this.$emit("input", this.localCheckedCities); // 通知父组件
                console.log(this.cities1, "222222222222222222222222222222222222");
            },
            // 单选勾选
            handleCheckedCitiesChange(value) {
                console.log(this.cities1, "shuzu1");

                // this.formLabelAlign.permissionsVOList = this.cities1.filter((city) =>
                //   value.includes(city.id)
                // );
                this.cities1.map((item) => {
                    if (value.includes(item.id)) {
                        item.isAssigned = true;
                    } else {
                        item.isAssigned = false;
                    }
                });
                this.localCheckAll = value.length === this.cities1.length;
                this.isIndeterminate =
                    value.length > 0 && value.length < this.cities1.length; // 改变全选状态
                console.log(this.cities1, "333333333333333333333333333333333333");
            },

        },
        created() {

        },
        mounted() {
            this.queryUserData();
            this.getKhData();


        },
    };
</script>
<style lang="less" scoped>
    /* 用户信息操作栏 */
    .xzl {
        border: 1px, solid, rgb(221, 221, 221);
        display: flex;
        background-color: rgb(240, 250, 254);

        // 上传+导入
        .xzl-div-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 48px;

            // 按钮公共样式
            .xzl-div-btns {
                height: 28px;
                line-height: 28px;
                padding: 0 10px;
                font-size: 12px;
                color: white;
                font-weight: 900;
                background-color: rgba(231, 102, 109);
                margin-left: 1vw;
            }

            .xzl-div-btns:hover {
                opacity: 0.8;
                /* 举例使用 Element UI 的主题色作为文字颜色 */
            }
        }
    }

    /deep/ .el-form-item {
        margin-bottom: 0px; //清空表格行间距margin
    }

    /deep/ .el-form-item__label {
        // border-right: 1px solid #eee !important;
        border-top: 1px solid #eee !important;
        height: 100px;
        /* 举例，根据需要设置 */
        display: flex;
        align-items: center;
        /* 垂直居中内容 */
    }

    /deep/ .el-form-item__content {
        border: 1px solid #eee;
    }
</style>
