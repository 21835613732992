<template>
  <!-- table组件 -->
  <div>
    <el-table
      ref="multipleTable"
      :data="tableData"
      :row-class-name="tableRowClassName"  
      border
      style="width: 100%"
      :header-cell-style="{ background: 'rgb(245,250,254)', color: '#000' }"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column
        v-for="(item, index) in tableTop"
        align="center"
        header-align="center"
        :key="index"
        :label="item.fieldName"
        :prop="item.field"
      >
      </el-table-column>
      <el-table-column
        v-if="roleName=='超级管理员'"
        header-align="center"
        align="center"
        label="操作"
        width="150"
        style="display: flex; justify-content: left"
      >
        <template slot-scope="scope">
          <i
            class="el-icon-edit"
            title="修改"
            @click="handleModify(scope.$index, scope.row)"
          ></i>
          <i
            class="el-icon-delete"
            title="删除"
            @click="clickNowRow(scope.$index, scope.row)"
          ></i>
          <!-- <i
            type="success"
            title="理赔"
            @click="handleEdit(scope.$index, scope.row)"
            >理赔</i
          > -->
        </template>
      </el-table-column>
    </el-table>
    <div style="margin-top: 5px">
      <el-button
        class="table-bottom-btns"
        style="background-color: rgb(231, 102, 109)"
        type="danger"
        @click="handleDelete()"
      >
        <i style="font-size: 15px" class="el-icon-delete"></i
        >批量删除</el-button
      >
     
      <el-button class="table-bottom-btns" @click="toggleSelection()">
        <i style="font-size: 15px" class="el-icon-s-release"></i
        >取消选择</el-button
      >
       <!-- <el-button
        class="table-bottom-btns"
        style="background-color: rgb(31, 02, 19)"
        type="info"
         @click="handleDeleteOA()"
      >
        <i style="font-size: 15px" class="el-icon-s-promotion"></i
        >提交OA</el-button
      > -->
    </div>

    <!-- 分页器 -->
    <div v-if="tableData.length" style="text-align: left; margin-top: 5px">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="pageSizes"
        :page-size="PageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="declCount"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { deleteTableData } from "@/axios/Home/index.js"; //删除
import { submitYsOA,submitLpOA } from "@/axios/tableTop"; //提交运输管理OA //提交理赔管理oa
export default {
  props: {
    // 表头数据
    tableTop: {
      type: Array,
      default: () => {
        return [];
      },
    },
    // 表格数据
    tableData: {
      type: Array,
      default: () => {
        return [];
      },
    },
    // 总条数
    declCount: {
      type: Number,
      default: 0,
    },
    // 当前险种类型
    insType:{
      type: String,
      default: "",
    },
    // 管理类型
    pageType:{
      type: String,
      default: "",
    }
  },
  data() {
    return {
      // 默认显示第几页
      currentPage: 1,
      // 总条数，根据接口获取数据长度(注意：这里不能为空)
      totalCount: 1,
      // 个数选择器（可修改）
      pageSizes: [50, 100],
      // 默认每页显示的条数（可修改）
      PageSize: 50,
      isLp: false, //true是理赔 false不是理赔
      isModify: false, //true是编辑 false不是编辑
      labelPosition: "right",
      formLabelAlign: {
        name: "",
        region: "",
        type: "",
      },
      multipleSelection: [], // 用于存储选中项
      records: [],
      roleName:JSON.parse(localStorage.getItem("roleName")),
      username:JSON.parse(localStorage.getItem("username")),
    };
  },
  methods: {
    // 判断table业务状态进行css样式修改
    tableRowClassName({row, rowIndex}) {  
      if (row.status === '导入失败') {     
        return 'orange-row';  
      } 
       if (row.status === 'OA审核不通过') {  
        return 'red-row';  
      }  
      // 默认情况下不返回任何类名，或返回一个默认类名  
      return '';  
    } ,
    handleSizeChange(val) {
      this.PageSize = val;
      this.$emit("sizeChange", val);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.$emit("currentChange", val);
    },
    // 表格取消 复选
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    // 暴露table数据 用来捞出excel
    getTableData() {
      // 返回表格数据，这里简单返回整个数组
      return this.tableData;
    },
    //  暴露tabletop数据 用来捞出excel
    getTableTop() {
      // 返回表格数据，这里简单返回整个数组
      return [
        this.tableTop.map((item) => item.fieldName),
        this.tableTop.map((item) => item.field),
      ];
    },
    // 理赔
    handleEdit() {
      this.isLp = true;
      this.$emit("isLp", this.isLp);
    },
    // 编辑
    handleModify(index, row) {
      this.isModify = true;
      this.$emit("isModify", this.isModify,row.id);
      this.$emit("rowData", row);
    },
    // 选中table 复选框数据
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 删除table 当前行
    clickNowRow(index, row) {
      this.$confirm("是否确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.records=[]; //防止不断push 导致数据重复
          let obj = row.id;
          this.records.push(obj);

          deleteTableData({ records: this.records,username:this.username }).then((res) => {
            if (res.code == 200) {

              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.$emit("querytable", true);
              this.records = [];
            }
          });

          // 操作删除唯一标识id
        })
        .catch(() => {});
    },
    // 删除 table选择删除
    handleDelete() {
      if (this.multipleSelection.length==0) {
        this.$message({
          type: "warning",
          message: "请选择要删除的数据!",
        });
        return;
      }else{
        this.$confirm("是否确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.records = this.multipleSelection.map((item) => item.id);
        deleteTableData({ records: this.records,username:this.username }).then((res) => {
          if (res.code == 200) {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.$emit("querytable", true);
            this.records = [];
          }
        });
        // 操作删除唯一标识id
      });
      }
     
    },
    // 提交OA
    handleDeleteOA(){
      if (this.multipleSelection.length==0) {
        this.$message({
          type: "warning",
          message: "请选择要提交的数据!",
        });
        return;
      }else{
        this.$confirm("此操作将提交OA, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.records = this.multipleSelection.map((item) => item.id);
        // 运输管理
        if (this.pageType == '0') {
          submitYsOA({ username:this.username,insType:this.insType,records: this.records }).then((res) => {
          this.$message({
            type:'success',
            message:'提交成功',
          });
          this.$emit("querytable", true);
          this.records = [];
        })
        }else{
          
          submitLpOA({ username:this.username,insType:this.insType,records: this.records }).then((res) => {
          this.$message({
            type:'success',
            message:'提交成功',
          });
          this.$emit("querytable", true);
          this.records = [];
        })
        }
       
      })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
      }
      
    }
  },
  mounted() {
    this.roleName=JSON.parse(localStorage.getItem("roleName"));
    
  },
};
</script>
<style lang="less" scoped>
/deep/.el-table .orange-row {  
  background-color: orange !important; /* 橙黄色 */  
}  
  
/deep/.el-table .red-row {  
  background-color: red !important; /* 红色 */  
} 
.table-bottom-btns {
  height: 28px;
  line-height: 28px;
  padding: 0 10px;
  font-size: 12px;
  color: white;
  font-weight: 900;
  background-color: rgba(0, 150, 136);
}

.table-bottom-btns:hover {
  opacity: 0.8;
  /* 举例使用 Element UI 的主题色作为文字颜色 */
}

// 强制清空table表格行间隙
/deep/.el-table .el-table__cell {
  padding: 4px 0;
}

i {
  margin-right: 6px;
  cursor: pointer;
}
/deep/.el-table .cell {
  font-size: 12px !important;
  padding: 0px;
}
</style>
