<template>
  <!--     :size="size" -->
  <el-descriptions class="margin-top" title="手续费设置" :column="1" border>
    <template slot="extra">
      <el-button type="primary" size="small" @click="qrtj">确认提交</el-button>
    </template>
    <el-descriptions-item>
      <template slot="label">

        新车货运险
      </template>
      <div style="display: flex">
        <div>
          手续费利率
          <el-input
            size="mini"
            style="width: 80px"
            v-model="dataArray[3].feeRate"
          >
          </el-input>
          %
        </div>
        <div style="margin-left: 2vw">
          管理成本率
          <el-input
            size="mini"
            style="width: 80px"
            v-model="dataArray[3].managementCost"
          >
          </el-input>
          %
        </div>
      </div>
    </el-descriptions-item>
    <el-descriptions-item>
      <template slot="label">
     
        二手车货运险
      </template>
      <div style="display: flex">
        <div>
          手续费利率
          <el-input
            size="mini"
            style="width: 80px"
            v-model="dataArray[4].feeRate"
          >
          </el-input>
          %
        </div>
        <div style="margin-left: 2vw">
          管理成本率
          <el-input
            size="mini"
            style="width: 80px"
            v-model="dataArray[4].managementCost"
          >
          </el-input>
          %
        </div>
      </div>
    </el-descriptions-item>
    <el-descriptions-item>
      <template slot="label">
      
        商业险
      </template>
      <div style="display: flex">
        <div>
          手续费利率
          <el-input
            size="mini"
            style="width: 80px"
            v-model="dataArray[0].feeRate"
          >
          </el-input>
          %
        </div>
        <div style="margin-left: 2vw">
          管理成本率
          <el-input
            size="mini"
            style="width: 80px"
            v-model="dataArray[0].managementCost"
          >
          </el-input>
          %
        </div>
      </div>
    </el-descriptions-item>
    <el-descriptions-item>
      <template slot="label">
       
        交强险
      </template>
      <div style="display: flex">
        <div>
          手续费利率
          <el-input
            size="mini"
            style="width: 80px"
            v-model="dataArray[2].feeRate"
          >
          </el-input>
          %
        </div>
        <div style="margin-left: 2vw">
          管理成本率
          <el-input
            size="mini"
            style="width: 80px"
            v-model="dataArray[2].managementCost"
          >
          </el-input>
          %
        </div>
      </div>
    </el-descriptions-item>
    <el-descriptions-item>
      <template slot="label">
     
        员工雇主责任险
      </template>
      <div style="display: flex">
        <div>
          手续费利率
          <el-input
            size="mini"
            style="width: 80px"
            v-model="dataArray[6].feeRate"
          >
          </el-input>
          %
        </div>
        <div style="margin-left: 2vw">
          管理成本率
          <el-input
            size="mini"
            style="width: 80px"
            v-model="dataArray[6].managementCost"
          >
          </el-input>
          %
        </div>
      </div>
    </el-descriptions-item>
    <el-descriptions-item>
      <template slot="label">

        车辆雇主险
      </template>
      <div style="display: flex">
        <div>
          手续费利率
          <el-input
            size="mini"
            style="width: 80px"
            v-model="dataArray[1].feeRate"
          >
          </el-input>
          %
        </div>
        <div style="margin-left: 2vw">
          管理成本率
          <el-input
            size="mini"
            style="width: 80px"
            v-model="dataArray[1].managementCost"
          >
          </el-input>
          %
        </div>
      </div>
    </el-descriptions-item>
    <el-descriptions-item>
      <template slot="label">
    
        财产险
      </template>
      <div style="display: flex">
        <div>
          手续费利率
          <el-input
            size="mini"
            style="width: 80px"
            v-model="dataArray[5].feeRate"
          >
          </el-input>
          %
        </div>
        <div style="margin-left: 2vw">
          管理成本率
          <el-input
            size="mini"
            style="width: 80px"
            v-model="dataArray[5].managementCost"
          >
          </el-input>
          %
        </div>
      </div>
    </el-descriptions-item>
    <el-descriptions-item>
      <template slot="label">

        货运三者险
      </template>
      <div style="display: flex">
        <div>
          手续费利率
          <el-input
            size="mini"
            style="width: 80px"
            v-model="dataArray[7].feeRate"
          >
          </el-input>
          %
        </div>
        <div style="margin-left: 2vw">
          管理成本率
          <el-input
            size="mini"
            style="width: 80px"
            v-model="dataArray[7].managementCost"
          >
          </el-input>
          %
        </div>
      </div>
    </el-descriptions-item>


    <el-descriptions-item>
      <template slot="label">
    
        商品车运输险
      </template>
      <div style="display: flex">
        <div>
          手续费利率
          <el-input
            size="mini"
            style="width: 80px"
            v-model="dataArray[8].feeRate"
          >
          </el-input>
          %
        </div>
        <div style="margin-left: 2vw">
          管理成本率
          <el-input
            size="mini"
            style="width: 80px"
            v-model="dataArray[8].managementCost"
          >
          </el-input>
          %
        </div>
      </div>
    </el-descriptions-item>
  </el-descriptions>
</template>

<script>
import { getSxf, bjSxf } from "@/axios/Xtsz/Xtsz/sxfsz.js";
export default {
  data() {
    return {
      dataArray: [
        { feeRate: 0, managementCost: 0 },
        { feeRate: 0, managementCost: 0 },
        { feeRate: 0, managementCost: 0 },
        { feeRate: 0, managementCost: 0 },
        { feeRate: 0, managementCost: 0 },
        { feeRate: 0, managementCost: 0 },
        { feeRate: 0, managementCost: 0 },
        { feeRate: 0, managementCost: 0 },
        { feeRate: 0, managementCost: 0 },
      ],
    };
  },
  methods: {
    // 初始化加载获取手续费设置
    getSxfData() {
      getSxf().then((res) => {
        this.dataArray = res.data;
      });
    },
    // 提交
    qrtj() {
      const extractedData = this.dataArray.map((policy) => ({
        free: Number(policy.feeRate),
        cost: Number(policy.managementCost),
        insType: policy.insType,
      }));
      bjSxf({ freeRequestList: extractedData }).then((res) => {
        this.$message.success("修改成功");
        // 刷新数据
        this.getSxfData()
      });
    },
  },
  mounted() {
    // 初始化加载获取手续费设置
    this.getSxfData();
  },
};
</script>

<style scoped>
/* 根据需要添加样式 */
</style>
